import React from "react"
import { withTrans } from "../i18n/withTrans"
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import "./theme.css";
import "./fonts.css";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { CSSPlugin } from 'gsap/CSSPlugin';
import Navbar from "../components/Navbar/Navbar"
import NavbarMobile from "../components/Navbar/NavbarMobile";
import Footer from "../components/Footer";
import CookiesNotification from "../components/CookiesNotification"
// import { GSDevTools } from '../utils/GSDevTools';

if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin, CSSPlugin);
}

const theme = {
    primary: '#000'
}

export const GlobalStyle = createGlobalStyle`

    @media (min-width: 768px) {
        .mobile_nav {
            display: none;
        }
    }
    
    @media (max-width: 767px) {
        .desktop_nav {
            display: none;
        }
    }

    * {
        box-sizing: border-box;
    }
  
    body {
        margin: 0;
        font-family: 'Montserrat', sans-serif;
        //font-size: 112.5%;
        font-size: 100%;
        line-height: 1.66;
    }
    
    main {
        min-height: calc(100vh - 584px);
    }
    
    h1, h2, h3, h4, h5, h6 {
        font-weight: 800;
    }
    
    h3 {
        margin-bottom: 0;
    }
    
    p, img {
        margin-bottom: 0;
    }
    
    ol {
        margin: 0;
    }
    
    input, textarea {
        font: inherit;
    }
    
    ::-webkit-scrollbar {
        width: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
        background-color: var(--textNormal);
        border-radius: 6px;
        border: 0;
        opacity: .5;
    }
    
    ::-webkit-scrollbar-track {
        background: var(--themeSwitcherHandle);
        opacity: .5;
    }
`;

const Layout = ({ children, location }) => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <NavbarMobile/>
            <Navbar />
            <main>{children}</main>
            <Footer location={location} />
            <CookiesNotification />
        </ThemeProvider>
    )
}

export default withTrans(Layout);
