import React from "react"
import styled, { css } from 'styled-components'
import { useTranslation } from "react-i18next"

const flexCenter = css`
  display: flex;
  align-items: center;
`;

const LocalesWrapper = styled.div`
  ${flexCenter};
  flex-direction: row;
  margin-right: 10px;

  button {
    ${flexCenter};
    margin: 0 4px;
    border: none;
    background: none;
    padding: 0;
    font-size: inherit;
    color: ${({ black }) => black ? 'var(--black)' : 'var(--white)'};
    cursor: pointer;
    transition: opacity .3s ease-in-out, text-shadow .3s ease-in-out;

    &:hover {
      opacity: 1!important;
      text-shadow: (var--navItemsHover);
    }
  }

  svg {
    width: 18px;
  }
`;


const LanguageMenu = ({ black }) => {
    const { i18n } = useTranslation()


    function handleChange(event) {
        i18n.changeLanguage(event.target.value)
    }

    return(
        <LocalesWrapper black={black}>
            <button
                style={ i18n.language === 'pl' ? { fontWeight: 'bold' } : { opacity: '.75' } }
                onClick={e => handleChange(e)}
                value={'pl'}
            >
                PL
            </button>
            <button
                style={ i18n.language === 'en' ? { fontWeight: 'bold' } : { opacity: '.75' } }
                onClick={e => handleChange(e)}
                value={'en'}
            >
                EN
            </button>
        </LocalesWrapper>
    )
}

export default LanguageMenu