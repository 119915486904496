import React, { createRef, useEffect, useRef, useState } from "react"
import styled from 'styled-components';
import { gsap } from 'gsap';
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"
import LanguageSwitcher from "../../i18n/languageSwitcher"
import { ButtonSpan } from "../Button/ButtonSpan"
import { StyledLogoWrapper } from "./StyledLogoWrapper"
import { navItemsList } from '../../data/navItems';
import Logo from "../Logo"
import PhoneIcon from '../../../static/utility_icons/phone_2.svg';
import MailIcon from '../../../static/utility_icons/mail_2.svg';
import useSiteMetadata from "../../hooks/useSiteMetadata"

const StyledNavbarMobile = styled.header`
    overflow-x: hidden;
    position: sticky;
        top: 0;
        left: 0;
        z-index: 5;
    width: 100%;
    touch-action: manipulation;
    
    .top_bar {
        display: flex;
            flex-direction: row;
            justify-content: space-between;
        padding: 1rem .9rem;
        background: rgba(255,255,255,0.99);
        @supports (backdrop-filter: blur(15px)) {
            & {
                backdrop-filter: blur(15px);
                -webkit-backdrop-filter: blur(15px);
                background: rgba(255,255,255,0.8);
            }
        }
    }
    
    .menu_items {
        position: fixed;
            top: 0;
            right: 0;
        display: grid;
            place-items: center;
        width: 100%;
        height: 100%;
        z-index: 5;
        backdrop-filter: blur(10px);
        background: rgba(88,88,88,0.99);
        transition: transform .2s ease-out, opacity .2s ease-out;
        transform: ${({ navOpened }) => navOpened ? 'translateX(0)' : 'translateX(100%)'};
        
        @supports (backdrop-filter: blur(15px)) {
            & {
                backdrop-filter: blur(15px);
                -webkit-backdrop-filter: blur(15px);
                background: rgba(88,88,88,0.85);
            }    
        }
        
        ul {
            list-style: none;
            display: grid;
            grid-template-columns: 1fr;
            place-items: center;
            grid-gap: 1.5rem;
            margin: 0;
            padding: 0;
            
            
            a {
                text-decoration: none;
                width: max-content;
                color: var(--white);
                transition: opacity .3s ease-out;
                
                &:hover {
                    opacity: .75;
                }
            }
        }
        
        .contact {
            //position: absolute;
            //bottom: 0;
            //left: 0;
            display: flex;
            gap: 1rem;
            color: var(--white);
            margin-top: 1rem;
            
            a {
                background: #000;
                border-radius: 50%;
                display: grid;
                padding: .5em;
                
                svg {
                    fill: white;
                    width: 1.5rem;
                    height: 1.5rem;
                }
            }
            
            
            p {
                margin: 0;
            }
        }
    }
    
    .burger {   
        position: fixed;
            bottom: 1rem;
            right: 1rem;
        display: grid;
            place-items: center;
        width: 3.5rem;
        height: 3.5rem;    
        padding: .6rem;
        border-radius: 50%;
        background: white;
        border: 1px solid var(--creme);
        z-index: 6;
        box-shadow: 0 0 10px -5px black;
        
        .burger__wrapper {
            display: inherit;
            place-items: inherit;
            width: 100%;
            height: 100%;
        }
        
        .burger__line {
            width: 100%;
            display: inherit;
            place-items: inherit;
            border-radius: 1rem;
            position: relative;
            height: 2px;
            //background: var(--black);
            background: ${({ navOpened }) => navOpened ? 'transparent' : 'var(--black)'};
            transition: background .2s ease-out;
            
            &::before, &::after {
                content: "";
                position: absolute;
                    top: 0;
                    left: 0;
                width: inherit;
                height: inherit;
                background: var(--black);
                border-radius: inherit;
                //{({ navOpened }) => navOpened ? 'unset' : 'left'};
                transition: .2s ease-out;
            }
            
            
            &::before {
                //transform: translateY(-8px);
                transform: ${({ navOpened }) => navOpened ? 'translateY(0) rotate(45deg) scaleX(.75)' : 'translateY(-8px) rotate(0) scaleX(.9)'}
            }
            
            &::after {
                //transform: translateY(8px);
                transform: ${({ navOpened }) => navOpened ? 'translateY(0) rotate(-45deg) scaleX(.75)' : 'translateY(8px) rotate(0) scaleX(0.8)'}

            }
        }
    }
`;

const NavbarMobile = () => {

    const { t } = useTranslation()

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const navRef = useRef();

    const [navItems] = useState(
        navItemsList.map(item => {
            return {
                ...item,
                ref: createRef()
            }
        }),
    );

    const [menuTL] = useState(
        gsap.timeline({
            paused: true,
            defaults: { duration: 1, ease: 'power4.out' }
        })
    );

    useEffect(() => {
        const itemsRefs = navItems.map(item => item.ref.current);

        menuTL
            .fromTo(navRef.current, { autoAlpha: 0 }, { autoAlpha: 1 })
            .fromTo(itemsRefs, { autoAlpha: 0, y: 48 }, { autoAlpha: 1, y: 0, stagger: 0.1 }, '-=0.4')
            .restart()
    }, []);

    useEffect(() => {
        menuTL.restart(!isMenuOpen);
    }, [isMenuOpen]);

    const phone = useSiteMetadata().phone;
    const mail = useSiteMetadata().mail;

    return (
        <StyledNavbarMobile className="mobile_nav" navOpened={isMenuOpen}>
            <div className="top_bar">
                <StyledLogoWrapper>
                    <Logo href="/" />
                </StyledLogoWrapper>
                <LanguageSwitcher black />
            </div>
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="burger" aria-label={t('navigation.open_nav')}>
                <span className="burger__wrapper">
                    <span className="burger__line">

                    </span>
                </span>
            </button>
            <div ref={navRef} className="menu_items">
                <ul>
                    {navItems.map(item => (
                        <li key={item.name} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                            { item.type === 'list' &&
                            <Link  to={item.link} ref={item.ref} activeClassName="active_mobile_nav">
                                {t(`navigation.${item.name}`)}
                            </Link>
                            }
                            { item.type === 'button' &&
                            <Link to={item.link} activeClassName="active_movile_nav_btn">
                                <ButtonSpan filled ref={item.ref}>
                                    {t('hero.estimate_project_btn')}
                                </ButtonSpan>
                            </Link>
                            }
                            { item.type === 'contact_button' &&
                                <div className="contact" ref={item.ref}>
                                    {item.body}
                                </div>
                            }
                        </li>
                    ))}
                </ul>
                {/*<div className="contact">*/}
                {/*    <p><span>📱 </span>+48 608 708 237</p>*/}
                {/*    <p><span>📧 </span>kontakt@panodstrony.pl</p>*/}
                {/*</div>*/}
            </div>
        </StyledNavbarMobile>
    )
}

export default NavbarMobile;

