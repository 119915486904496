import React from 'react';
import styled from 'styled-components'
import LanguageSwitcher from '../../i18n/languageSwitcher';
import Container from "../Container"
import PhoneIcon from '../../../static/utility_icons/phone_2.svg';
import MailIcon from '../../../static/utility_icons/mail_2.svg';
import useSiteMetadata from "../../hooks/useSiteMetadata"

const StyledTopNav = styled.div`
    //background: #071723;
    background: rgb(7,22,25);
    background: linear-gradient(90deg, rgba(7,22,25,1) 0%, rgba(15,32,112,1) 100%); 
    padding: 3px 1rem;
    box-sizing: content-box;

    & > div {
        display: flex;
        justify-content: space-between;
    }
    
    .contact_details {
        display: grid;
        grid-template-columns: 50% 1fr;
        
        .phone_number, .email_address {
            display: flex;
            align-items: center;
            color: var(--white);
            text-decoration: none;
            font-size: 75%;
        }
        
        svg {
            fill: var(--white);
            height: 20px;
        }
        
        .email_address svg {
            margin-right: 3px;
        }
    }
`;

const TopNav = () => {
    return (
        <div className="top_nav">
            <StyledTopNav className="top_nav">
                    <Container>
                        <div className="contact_details">
                            <a className="phone_number" href={`tel:${useSiteMetadata().phone}`}>
                                <PhoneIcon />
                                {useSiteMetadata().phone}
                            </a>
                            <a className="email_address" href={`mailto:${useSiteMetadata().mail}`}>
                                <MailIcon />
                                {useSiteMetadata().mail}
                            </a>
                        </div>
                        <LanguageSwitcher />
                    </Container>
            </StyledTopNav>
        </div>
    )
};

export default TopNav;

