import styled from "styled-components"

export const StyledLogoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    
    svg {
        height: 40px;
        width: auto;
    }
    
    a {
        display: flex;
        align-items: center;
    }
`;