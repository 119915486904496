import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"
import Container from '../Container';
import TopNav from "./TopNav"
import { ButtonSpan } from "../Button/ButtonSpan"
import { navItemsList } from '../../data/navItems';
import Logo from "../Logo"

const StyledNav = styled.header`
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    //box-shadow: 0 0 30px -20px var(--navActive);
    transition: background .3s ease-in, box-shadow .3s ease-in;
    background: rgba(247,247,247,0.99);
    
    @supports (backdrop-filter: blur(15px)) {
        & {
            backdrop-filter: blur(15px);
            -webkit-backdrop-filter: blur(15px);
            background: rgba(247,247,247,0.8);
        }    
    }
    //
    //@media (max-width: 768px) {
    //    display: none;
    //}
`;

const StyledNavItems = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 14px 0;
`;

const StyledNavItemsLinks = styled.div`
    ul {
        margin: 0;
        display: inline-flex;
        text-decoration: none;
        list-style: none;
        padding: 0;
        
        li {
            display: inline-grid;
            place-items: center;
            margin: 0;
        }
        
        a {
            display: flex;
            text-transform: lowercase;
            font-weight: 800;
            margin: 0 10px;
            text-decoration: none;
            //color: var(--navLinkColor);
            color: #222222;
            font-size: 16px;
            transition: color .3s ease-out;
            
            &:hover {
                color: var(--primary);
            }
            
            button {
                border: inherit;
                background: inherit;
                font: inherit;
                padding: 0;
            }
            
            //&:focus {
            //    border-bottom: 1px solid var(--primary);
            //}
            
        }
  }
  
  @media (max-width: 768px) {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding-bottom: 1.5rem;
      background: rgba(255,255,255,0.95);
      
      ul {
          li {
              a {
                  margin: 0 1px!important;
              }
          }
      }
    
      @supports (backdrop-filter: blur(15px)) {
          & {
              backdrop-filter: blur(15px);
              -webkit-backdrop-filter: blur(15px);
              background: rgba(255,255,255,0.8);
        
          }    
      }
      
      ul {
          width: 100%;
          justify-content: space-around;
      }
  } 
`;

const NavbarComponent = () => {

    const { t } = useTranslation()

    return (
        <StyledNav className="desktop_nav">
            <TopNav />
            <Container autoPadding>
                <StyledNavItems role="navigation">
                    <Logo href="/" />
                    {/*<StyledLogoWrapper>*/}
                    {/*        <StaticImage*/}
                    {/*            src="../../../static/logo_final.png"*/}
                    {/*            layout="fixed"*/}
                    {/*            alt=""*/}
                    {/*            grayscale="true"*/}
                    {/*            height={35}*/}
                    {/*            formats={["auto", "webp", "avif"]}*/}
                    {/*        />*/}
                    {/*</StyledLogoWrapper>*/}
                    <StyledNavItemsLinks>
                        <ul role="presentation">
                            {navItemsList.map(item => (
                                <li key={item.name}>
                                    { item.type === 'list' &&
                                        <Link tabIndex="0" role="button" to={item.link} ref={item.ref} activeClassName="active_nav">
                                            {t(`navigation.${item.name}`)}
                                        </Link>
                                    }
                                    { item.type === 'button' &&
                                        <Link tabIndex="0" role="button" to={item.link} activeClassName="active_nav_button">
                                            <ButtonSpan filled ref={item.ref}>
                                                {t('hero.estimate_project_btn')}
                                            </ButtonSpan>
                                        </Link>
                                    }
                                </li>
                            ))}
                        </ul>
                    </StyledNavItemsLinks>
                </StyledNavItems>
            </Container>
        </StyledNav>
    )};


export default NavbarComponent;