import React from 'react';
import styled from "styled-components";
import PhoneIcon from '../../static/utility_icons/phone_2.svg'
import MailIcon from '../../static/utility_icons/mail_2.svg'


export const navItemsList = [
    {
        name: 'home',
        link: '/',
        type: 'list',
        body: ''
    },
    {
        name: 'info',
        link: '/info/',
        type: 'list',
        body: ''
    },
    {
        name: 'projects',
        link: '/projekty/',
        type: 'list',
        body: ''
    },
    {
        name: 'blog',
        link: '/blog/',
        type: 'list',
        body: ''
    },
    {
        name: 'contact',
        link: '/kontakt/',
        type: 'list',
        body: ''
    },
    {
        name: 'estimate_project_btn',
        link: '/wycena/',
        type: 'button',
        body: ''
    },
    {
        name: 'contact_details',
        link: 'tel:+48608709237',
        type: 'contact_button',
        body: <>
            <a href="tel:+48608709237"><PhoneIcon /></a>
            <a href="mailto:kontakt@panodstrony.pl"><MailIcon /></a>
        </>
    },
];

export const websiteUrl = 'https://panodstrony.pl'